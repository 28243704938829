/* Navbar.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px 10px;
    background-color: transparent;
    color: #000;
    font-weight: 600;
    z-index: 2;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    transition: padding 0.5s, background-color 0.5s;;

    &.shrink {
        padding: 0.1% 1%;
        background-color: #7b68ee;
        color: #fff;

        .links {
            .navlink{
                color: #fff;
            }
        }
    }

    
    .logo {

        & img{
            height: 100px;
        }
    }
      
    .links {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        flex-grow: 1;
        cursor: pointer;

        .navlink{
            text-decoration: none;
            color: #000;
            font-weight: 600;
        }
    }

    .menu-icon {
        display: none;
        cursor: pointer;
        z-index: 3000;
  
        .bar {
          background-color: #010101;
          height: 3px;
          width: 25px;
          margin: 5px auto;
          transition: 0.4s;
        }
  
        .animate {
            background-color: #7b68ee;
  
            &:nth-child(1) {
                transform: rotate(-45deg) translate(-5px, 6px);
            }
  
            &:nth-child(2) {
                opacity: 0;
            }
  
            &:nth-child(3) {
                transform: rotate(45deg) translate(-5px, -6px);
            }
        }
    }        

    @media (max-width: 540px) {
        .links {
            display: none;

            &.active {
                display: flex;
                flex-direction: column;
                position: fixed;
                top: 0;
                right: 0;
                width: 50%;
                height: 100%;
                background-color: antiquewhite;
                gap: 45px;
                padding: 65px;
                box-shadow: -5px 0 10px rgba(0, 0, 0, 0.5);
                z-index: 2000;
                color: #000 !important;
            }

            .navlink{
                color: #000 !important;
            }
        }

        .menu-icon {
            display: block;
        }

        .overlay {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 1000;

            &.act {
                display: block;
            } 
        }
    }
}
  