.carousel-container {
    padding-top: 5%;
    width: 100%;
    background-color:  #7b68ee;
    .carousel-slide {
        img {
            height: 50vw;
            width: 100%;
        }
    }
}
  